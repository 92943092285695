import {LocationQuery} from "vue-router";

window.ymData = {
    id: "",
    clientId: ""
}

export const ymInit = (query: LocationQuery) => {
    // Яндекс Метрика

    if (!window.ym) return;

    let ymConfig: any = {}

    if (document.referrer.includes('vegas-hall.ru')) {
        ymConfig = {
            id: '39017335',
            clickmap: true,
            trackLinks: true,
            accurateTrackBounce: true,
            ecommerce: "dataLayer",
            //webvisor: true,
            //trackHash: true
        }

    } else {
        for (const key of Object.keys(query))
            if (key.includes('ym_'))
                ymConfig[key.replace('ym_', '')] = query[key]

    }


    if (Object.keys(ymConfig).length) {
        window.ymData.id = ymConfig.id;
        window.ym(ymConfig.id, "init", ymConfig);
        window.ym(ymConfig.id, 'getClientID', (clientID: string) => {
            window.ymData.clientId = clientID.toString();
        })

    }

}

