

import SvgMap from "@/components/Map.vue";
import {Component, Vue} from "vue-facing-decorator";
import {ymInit} from "@/ym";

@Component({
  components: {
    SvgMap
  }
})
export default class App extends Vue {

  isInIFrame() {
    return (window.top !== window.self) || this.$route.query["debug"]
  }

  created() {

    if (!process.env.VUE_APP_API_URL)
      console.error('env.VUE_APP_API_URL not defined!')

    ymInit(this.$route.query)

  }

}

