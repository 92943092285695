import axios from "axios";
import {CreateOrderData, EventData, OrderData, SegmentsDiscounts} from "@/@types/api";

function getFireUrl(apiUrl: string) {
    return apiUrl
        .replace('https://api.crocushall.ru', '')
        .replace(/[/]/g, '-')
        .replace(/[.!:]/g, '')
}

//const ROOT_URL = 'https://api.crocushall.ru/widget2/hs/widget/'
//export const API_URL = 'https://api.crocushall.ru:449/widget2.dev/hs/widget'
export const API_URL = process.env.VUE_APP_API_URL ?? ''
export const FIRE_URL = getFireUrl(API_URL)

const configAuth = {
    auth: {
        // берем корневой домен, например vegas-hall.ru
        username: '', // todo через несколько дней убрать, когда кеш у клиентов удалится и всегда будет стартер передавать домен
        password: ''
    }
}

export function setRootDomain(domain: string) {
    configAuth.auth.username = domain
}

// null нет данных, undefined ошибка
export async function getEvent(eventId: string, debugKey = ""): Promise<EventData | null | undefined> {
    // if (!eventId) return undefined;
    //return (await axios.get(`${API_URL}/events/${eventId}`)).data.data

    try {
        // todo тут можно добавить несколько попыток оптравить запрос
        return (await axios.get(`${API_URL}/events/${eventId}` + (debugKey ? '?debug=' + debugKey : ''), configAuth))
            .data.data
    } catch (error) {

        return undefined
        //
        // // error.response  - сервер ответил, но не 2хх (валидные коды можно задать в конфиге вызова и сделать 404 валидным)
        // if (error?.response?.status === 404) {
        //     return null
        //
        // } else if (error.request) {
        //     // The request was made but no response was received
        //     // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        //     // http.ClientRequest in node.js
        //     throw Error
        // } else {
        //     // Something happened in setting up the request that triggered an Error
        //     throw Error
        // }

    }

}

// export async function createOrder(data: CreateOrderData): Promise<OrderData> {
//
//     const result = (await axios.post(`${API_URL}/online-orders`, data)).data.data as OrderData | undefined
//     if (result?.payUrl?.includes('http'))
//         return result
//     else
//         throw Error('Invalid createOrder result')
// }

export async function createOnlineOrder(data: CreateOrderData, sbp = false): Promise<OrderData> {

    return new Promise((resolve, reject) => {
            axios.post(`${API_URL}/orders?sbp=${sbp}`, data, configAuth)
                .then(result => {
                    resolve(result.data.data)
                })
                .catch(err => {
                    reject(err)
                })
        }
    )

}

// export function cancelOrder(orderId) {
//      //axios.get(`${ROOT_URL}order_cancel`, orderId)
// }


export async function getPromocodeData(eventId: string, promocode: string, segments: number[]): Promise<SegmentsDiscounts> {
    return new Promise((resolve, reject) => {
            axios.get(`${API_URL}/promocode/${eventId}/${promocode}`,
                {
                    ...configAuth, params: {segments: segments.join(',')}
                })
                .then(result => {
                    // от 1с придет с кодом сегмента строковым
                    <{ [segmentId: string]: number }>result.data.data

                    const segmentDiscounts: SegmentsDiscounts = new Map
                    for (const key in result.data.data)
                        segmentDiscounts.set(Number(key), result.data.data[key])

                    resolve(result.data.data)
                })
                .catch(err => {
                    reject(err)
                })
        }
    )

}
